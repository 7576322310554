<template>
  <div class="scheduling-buttons d-flex flex-column">
    <v-btn
      v-for="{ name, label } of $options.tasks"
      :key="name"
      :loading="pendingRequests[name]"
      :color="buttonColor[name]"
      class="mb-10"
      @click="onTaskInvokeClick(name)"
    >
      {{ label }}
    </v-btn>
  </div>
</template>

<script>
import { invokeTask } from '@/api/scheduling';

import { TASK_NAME, VALID_TASK_NAMES } from '@/constants/scheduling';

const TASK_LABEL = {
  [TASK_NAME.CHECK_ORDER_PARCEL_DELIVERY_STATUS]: 'Check order parcel delivery status',
  [TASK_NAME.COMMON_SCHEDULED_OPERATIONS]: 'Invoke common scheduled operations',
  [TASK_NAME.DAILY_COMMON_SCHEDULED_OPERATIONS]: 'Invoke daily common scheduled operations',
  [TASK_NAME.DAILY_QUANTITY_UPDATE]: 'Invoke daily quantity update',
  [TASK_NAME.NOTIFY_ABOUT_SUCCESSFUL_ORDER_PAYMENT]: 'Notify about successful order payment',
  [TASK_NAME.PROCESS_ORDER_TRACKING_NUMBER_WAITING]: 'Process order tracking number waiting',
  [TASK_NAME.PUSH_TRUSTPILOT_LAMBDA]: 'Push Trustpilot lambda',
  [TASK_NAME.REMIND_PATIENTS_ABOUT_OFFER_SENT_BY_DOCTOR]:
    'Remind patients about offer sent by doctor',
  [TASK_NAME.RESET_DOCTOR_MESSAGES_COUNTER]: 'Reset doctor messages counter',
  [TASK_NAME.SEND_ORDERS_TO_DISTRIBUTORS]: 'Send orders to distributors',
  [TASK_NAME.WARN_ADMIN_ABOUT_DELAYED_DELIVERY]: 'Warn admin about delayed delivery',
  [TASK_NAME.VERIFY_TREATMENTS]: 'Send autopilot offers',
  [TASK_NAME.CHECK_PAYPAL_TRANSACTION_STATUS]: 'Check PayPal transactions status',
  [TASK_NAME.ENSURE_AVAILABILITY_OF_EXCHANGE_RATE]: 'Ensure availability of exchange rate'
};

const TASKS = VALID_TASK_NAMES.map(name => ({
  name,
  label: TASK_LABEL[name]
}));

export default {
  name: 'SchedulingTasksList',
  tasks: TASKS,
  data() {
    return {
      pendingRequests: {},
      buttonColor: {}
    };
  },
  methods: {
    async onTaskInvokeClick(name) {
      if (!VALID_TASK_NAMES.includes(name)) {
        throw Error(`Unknown task "${name}"`);
      }

      this.setButtonLoading(name, true);

      try {
        await invokeTask(name);

        this.setButtonStatus(name, true);
      } catch (error) {
        this.setButtonStatus(name, false);
      }

      this.setButtonLoading(name, false);
    },
    setButtonLoading(name, isLoading) {
      this.pendingRequests = {
        ...this.pendingRequests,
        [name]: isLoading
      };
    },
    setButtonStatus(name, isSuccess) {
      this.buttonColor = {
        ...this.buttonColor,
        [name]: isSuccess ? 'success' : 'error'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.scheduling-buttons {
  width: 450px;
  max-width: 100%;
  margin: 20px auto;
}
</style>
