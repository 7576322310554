export const TASK_NAME = {
  CHECK_ORDER_PARCEL_DELIVERY_STATUS: 'checkOrderParcelDeliveryStatus',
  COMMON_SCHEDULED_OPERATIONS: 'commonScheduledOperations',
  DAILY_COMMON_SCHEDULED_OPERATIONS: 'dailyCommonScheduledOperations',
  DAILY_QUANTITY_UPDATE: 'dailyQuantityUpdate',
  NOTIFY_ABOUT_SUCCESSFUL_ORDER_PAYMENT: 'notifyAboutSuccessfulOrderPayment',
  PROCESS_ORDER_TRACKING_NUMBER_WAITING: 'processOrderTrackingNumberWaiting',
  PUSH_TRUSTPILOT_LAMBDA: 'pushTrustpilotLambda',
  REMIND_PATIENTS_ABOUT_OFFER_SENT_BY_DOCTOR: 'remindPatientsAboutOfferSentByDoctor',
  RESET_DOCTOR_MESSAGES_COUNTER: 'resetDoctorMessagesCounter',
  SEND_ORDERS_TO_DISTRIBUTORS: 'sendOrdersToDistributors',
  WARN_ADMIN_ABOUT_DELAYED_DELIVERY: 'warnAdminAboutDelayedDelivery',
  VERIFY_TREATMENTS: 'verifyTreatments',
  CHECK_PAYPAL_TRANSACTION_STATUS: 'checkPayPalTransactionsStatus',
  ENSURE_AVAILABILITY_OF_EXCHANGE_RATE: 'ensureAvailabilityOfExchangeRate'
};

export const VALID_TASK_NAMES = Object.values(TASK_NAME);
