import httpClient from '@/api/httpClient';

import { TASK_NAME } from '@/constants/scheduling';

const TASK_URL = {
  [TASK_NAME.CHECK_ORDER_PARCEL_DELIVERY_STATUS]: '/api/scheduled/checkOrderParcelDeliveryStatus',
  [TASK_NAME.COMMON_SCHEDULED_OPERATIONS]: '/api/scheduled/commonScheduledOperations',
  [TASK_NAME.DAILY_COMMON_SCHEDULED_OPERATIONS]: '/api/scheduled/dailyCommonScheduledOperations',
  [TASK_NAME.DAILY_QUANTITY_UPDATE]: '/api/scheduled/dailyQuantityUpdate',
  [TASK_NAME.NOTIFY_ABOUT_SUCCESSFUL_ORDER_PAYMENT]:
    '/api/scheduled/notifyAboutSuccessfulOrderPayment',
  [TASK_NAME.PROCESS_ORDER_TRACKING_NUMBER_WAITING]:
    '/api/scheduled/processOrderTrackingNumberWaiting',
  [TASK_NAME.PUSH_TRUSTPILOT_LAMBDA]: '/api/scheduled/pushTrustpilotLambda',
  [TASK_NAME.REMIND_PATIENTS_ABOUT_OFFER_SENT_BY_DOCTOR]:
    '/api/scheduled/remindPatientsAboutOfferSentByDoctor',
  [TASK_NAME.RESET_DOCTOR_MESSAGES_COUNTER]: '/api/scheduled/resetDoctorMessagesCounter',
  [TASK_NAME.SEND_ORDERS_TO_DISTRIBUTORS]: '/api/scheduled/sendOrdersToDistributors',
  [TASK_NAME.WARN_ADMIN_ABOUT_DELAYED_DELIVERY]: '/api/scheduled/warnAdminAboutDelayedDelivery',
  [TASK_NAME.VERIFY_TREATMENTS]: '/api/scheduled/sendAutopilotTreatments',
  [TASK_NAME.CHECK_PAYPAL_TRANSACTION_STATUS]: '/paypal/ensureNoUnprocessedPayments',
  [TASK_NAME.ENSURE_AVAILABILITY_OF_EXCHANGE_RATE]: '/api/adminPanel/exchangeRates/ensureAvailability'
};

const invokeTask = name => {
  const url = TASK_URL[name];

  return httpClient.post(url);
};

export { invokeTask };
