<template>
  <div class="fill-height white">
    <section class="scheduling-section">
      <header
        class="scheduling-section__header d-flex align-center mb-10 px-5 title font-weight-regular"
      >
        Scheduling Tasks
      </header>
      <scheduling-tasks-list />
    </section>
  </div>
</template>

<script>
import SchedulingTasksList from '@/components/scheduling-tasks/SchedulingTasksList.vue';

export default {
  name: 'SchedulingTasks',
  components: { SchedulingTasksList }
};
</script>

<style lang="scss" scoped>
.scheduling-section {
  &__header {
    height: 80px;
  }
}
</style>
